import { Link } from "react-router-dom";

const Community = () => {
  return (
    <div>
      <div className="bg-blue-800 text-white h-32 justify-center flex">
        <h1 className="text-3xl m-auto">Our Services and Sessions</h1>
      </div>
      <div className="w-full md:md:w-3/4 flex m-auto flex-col">
        <h2 className="text-2xl text-blue-800 mt-4">How we can help you</h2>
        <p className="mt-4 text-xl">
          All of our sessions whether group, family or 1-2-1 are based in person
          and remotely to provide flexibility and options to suit your needs.
          These sessions are held in the strictest confidence and are not shared
          outside of the intended audience.
        </p>
        <p className="mt-4 text-xl">
          Our group sessions are aimed at providing those affected with the
          opportunity to share their experiences, emotions and circumstances in
          a safe and open environment with others who are facing the same
          situations. Regardless of where people are in their journey, the
          sessions allow for anyone to impart their experiences and ways of
          progressing forward.
        </p>
        <p className="mt-4 text-xl">
          Our 1-2-1 sessions are targeted at those who wish for a more private
          setting but still want to seek support and a personal buddy that they
          can connect with. These can be held in locations that are safe for the
          client such as Cafés, their home or for a social walk in a quiet
          place.
        </p>
        <p className="mt-4 text-xl">
          Our family sessions allow families to discuss their current concerns,
          fears, and struggles and seek guidance in a private session with a
          buddy who will provide their insights, and a chance to discuss
          anythingelse in total privacy.
        </p>
        <p className="mt-4 text-xl">
          Our services cater to everyone who is going through this difficult
          experience, and we understand the importance of ensuring everyone can
          access it. That is why we offer discounted or covered sessions for
          those who may otherwise be unable to benefit from our Nonprofit. For
          any questions about this, please get in touch by going to our contacts
          page.
        </p>
      </div>
      <div className="md:md:w-3/4 m-auto mt-4">
        <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-7">
          <div className="bg-purple-700 text-white flex flex-col justify-center">
            <div>
              <h2 className="text-center text-3xl mb-2 ml-2 mr-2">
                Booking a session
              </h2>
              <p className="text-center ml-2 mr-2">
                Interested in booking into one of our sessions? Please see our
                booking service below
              </p>
              <div className="flex justify-center">
                <Link
                  to="/book"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 m-auto mt-7"
                >
                  Our booking page
                </Link>
              </div>
            </div>
          </div>
          <div className="bg-blue-900 text-white flex flex-col justify-center">
            <h2 className="text-center text-3xl mb-2 mt-6 ml-2 mr-2">
              Get in touch
            </h2>
            <p className="text-center ml-2 mr-2">
              Not sure if a session is right for you or need any additional
              support? Get in touch with us via the form below and we'll get
              back to you
            </p>
            <div className="flex justify-center">
              <Link
                to="/contact"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800  m-auto mt-7 mb-6"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Community;
