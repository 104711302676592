import { useEffect, useState, useRef } from "react";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();

  const [nameValue, setName] = useState("");
  const [emailValue, setEmail] = useState("");
  const [queryValue, setQuery] = useState("");
  const [sendingText, setSendText] = useState("Submit");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);

  useEffect(() => emailjs.init("Scrmn43GP_EG4P1Ng"), []);
  const submitForm = (e) => {
    e.preventDefault();
    setSendText("Sending....");
    emailjs
      .sendForm("default_service", "template_w2kuoxb", form.current)
      .then((response) => {
        if (response.status === 200) {
          setFormSubmitted(true);
        } else {
          setSendText("Submit");
          setFormError(true);
        }
      })
      .catch((error) => {
        setSendText("Submit");
        setFormError(true);
      });
  };

  if (formSubmitted) {
    return (
      <div className="m-3 md:w-3/4 md:m-auto mt-4">
        <h1 className="text-center text-4xl mb-4">Form submitted</h1>
        <p className="text-xl mb-4">
          Thanks for getting in touch with us, we'll get back to you as soon as
          we can
        </p>
      </div>
    );
  }
  return (
    <div className="m-3 md:w-3/4 md:m-auto mt-4">
      <h1 className="text-center text-4xl mb-4">Contact Us</h1>
      <div className="w-1/2 m-auto">
        <h2 className="text-xl mb-4">
          Got a quesiton for us that there's not an answer for on the website or
          simply want to know more? Get in touch with us using the form below
          and we'll get back to you!
        </h2>
        <form ref={form} onSubmit={(e) => submitForm(e)}>
          <div className="mb-5">
            <label
              for="contact_name"
              className="mb-3 block text-base font-medium text-[#07074D]"
            >
              Your name:{" "}
            </label>
            <input
              type="text"
              name="contact_name"
              className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              value={nameValue}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="mb-5">
            <label
              for="contact_email"
              className="mb-3 block text-base font-medium text-[#07074D]"
            >
              Your email:{" "}
            </label>
            <input
              type="text"
              name="contact_email"
              value={emailValue}
              className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-5">
            <label
              for="contact_query"
              className="mb-3 block text-base font-medium text-[#07074D]"
            >
              What do you want to ask us about?
            </label>
            <textarea
              name="contact_query"
              value={queryValue}
              className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          {formError && (
            <div>
              <p className="text-red-500">
                Form submission failed, please make sure you've filled in all
                fields and try again
              </p>
            </div>
          )}
          <div className="text-center">
            <input
              type="submit"
              value={sendingText}
              className="hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-base font-semibold text-white outline-none mb-4"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
