import "./App.css";

import { useState } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import HomePage from "./Containers/Home";
import Contact from "./Containers/Contact";
import Book from "./Containers/Book";
import Privacy from "./Containers/Privacy";
import Community from "./Containers/AboutPages/Community";
import Mission from "./Containers/AboutPages/Mission";
import People from "./Containers/AboutPages/People";
import Services from "./Containers/AboutPages/Services";
import Cookies from "./Containers/Cookies";
import TermsAndConditions from "./Containers/TermsAndConditions";
import ScrollToTop from "./helpers/ScrollToTop";

const App = () => {
  const [isOpen, setOpen] = useState(false);
  const [isBurgerOpen, setBurgerOpen] = useState(false);

  const handleDropDown = () => {
    setOpen(!isOpen);
  };

  const handleBurger = () => {
    setBurgerOpen(!isBurgerOpen);
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <nav className="bg-white border-gray-200 dark:bg-gray-900 dark:bo rder-gray-700">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <Link
            to="/"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <img
              src="https://i.imgur.com/MUXDGT9.png"
              className="h-20"
              alt="F and FS Logo"
            />
          </Link>
          <div className="md:hidden">
            <button
              className="navbar-burger flex items-center text-blue-600 p-3"
              onClick={handleBurger}
            >
              <svg
                className="block h-4 w-4 fill-current"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Mobile menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
              </svg>
            </button>
          </div>
          <div
            className="hidden w-full md:block md:w-auto"
            id="navbar-dropdown"
          >
            <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <button
                  id="dropdownNavbarLink"
                  data-dropdown-toggle="dropdownNavbar"
                  className="flex items-center justify-between w-full py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto dark:text-white md:dark:hover:text-blue-500 dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent"
                  onClick={handleDropDown}
                >
                  About{" "}
                  <svg
                    className="w-2.5 h-2.5 ms-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>
                <div
                  id="dropdownNavbar"
                  className={`z-10 font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600 absolute ${
                    isOpen ? "block" : "hidden"
                  }`}
                >
                  <ul
                    className="py-2 text-sm text-gray-700 dark:text-gray-400"
                    aria-labelledby="dropdownLargeButton"
                  >
                    <li>
                      <Link
                        to="/people"
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Our People
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/services"
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Our Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/mission"
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Our Mission
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/community"
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Our Community impact
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  Contact
                </Link>
              </li>
              <li>
                <Link
                  to="/book"
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  Book
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div
        className={`navbar-menu relative z-50 ${isBurgerOpen ? "block" : "hidden"}`}
      >
        <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
        <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
          <div className="flex items-center mb-8">
            <Link className="mr-auto text-3xl font-bold leading-none" to="/">
              <img
                src="https://i.imgur.com/MUXDGT9.png"
                className="h-20"
                alt="F and FS Logo"
              />
            </Link>
            <button className="navbar-close" onClick={handleBurger}>
              <svg
                className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <div>
            <ul>
              <li className="mb-1">
                <p
                  className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-blue-600 rounded"
                  href="#"
                >
                  About
                </p>
                <ul>
                  <li className="mb-1/2 ml-5">
                    <Link
                      className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-blue-600 rounded"
                      to="/people"
                      onClick={() => setBurgerOpen(false)}
                    >
                      Our People
                    </Link>
                  </li>
                  <li className="mb-1/2 ml-5">
                    <Link
                      className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-blue-600 rounded"
                      to="/services"
                      onClick={() => setBurgerOpen(false)}
                    >
                      Our Services
                    </Link>
                  </li>
                  <li className="mb-1/2 ml-5">
                    <Link
                      className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-blue-600 rounded"
                      to="/mission"
                      onClick={() => setBurgerOpen(false)}
                    >
                      Our Mission
                    </Link>
                  </li>
                  <li className="mb-1/2 ml-5">
                    <Link
                      className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-blue-600 rounded"
                      to="/community"
                      onClick={() => setBurgerOpen(false)}
                    >
                      Our Community Impact
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="mb-1">
                <Link
                  className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-blue-600 rounded"
                  to="/book"
                  onClick={() => setBurgerOpen(false)}
                >
                  Book
                </Link>
              </li>
              <li className="mb-1">
                <Link
                  className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-blue-600 rounded"
                  to="/contact"
                  onClick={() => setBurgerOpen(false)}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <div className="min-h-screen flex flex-col">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/book" element={<Book />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/people" element={<People />} />
          <Route path="/community" element={<Community />} />
          <Route path="/services" element={<Services />} />
          <Route path="/mission" element={<Mission />} />
          <Route path="/cookies" element={<Cookies />} />
          <Route path="/terms" element={<TermsAndConditions />} />
        </Routes>
      </div>
      <div className="bg-purple-700 pb-10 mt-auto">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-7 w-2/3 m-auto">
          <div className="mt-4">
            <h4 className="text-white text-2xl font-bold">Contact Us</h4>
            <p className="text-white mt-3">enquiries@fandfs.co.uk</p>
            <p className="text-white mt-3">07359196553</p>
          </div>
          <div className="mt-4">
            <h4 className="text-white text-2xl font-bold">Policies</h4>
            <Link className="text-white mt-3 block" to="/privacy">
              Privacy Policy
            </Link>
            <Link className="text-white mt-1 block" to="/cookies">
              Cookie Policy
            </Link>
            <Link className="text-white mt-1 block" to="/terms">
              Terms and Conditions
            </Link>
          </div>
          <div className="mt-4">
            <h4 className="text-white text-2xl font-bold mb-3">Our Socials</h4>
            <a
              href="https://twitter.com/fandfscic"
              className="fa fa-twitter"
              target="_blank"
            ></a>
            <a
              href="https://www.linkedin.com/company/family-friends-stronghold-cic/about/?viewAsMember=true"
              className="fa fa-linkedin"
              target="_blank"
            ></a>
            <a
              href="https://www.instagram.com/fandfscic"
              className="fa fa-instagram"
              target="_blank"
            ></a>
          </div>
        </div>
        <div className="text-white w-2/3 m-auto items-center justify-center mt-5">
          Company Number: 15474368 registered in England
        </div>
        <div className="text-white w-2/3 m-auto items-center justify-center mt-2">
          ICO Reference: ZB666419
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
