import { useState, useRef, useEffect } from "react";
import emailjs from "@emailjs/browser";

const Book = () => {
  const form = useRef();

  const [nameValue, setName] = useState("");
  const [emailValue, setEmail] = useState("");
  const [contactNumberValue, setContactNumber] = useState("");
  const [sessionSelection, setSessionSelection] = useState("");
  const [timeslotValue, setTimeslotValue] = useState("");
  const [questionValue, setQuestionValue] = useState("");
  const [sendingText, setSendText] = useState("Submit");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);
  const [sessionInfo, setSessionInfo] = useState("");

  const sessionsWithTimeslots = [
    "oneToOneRemote",
    "oneToOnePerson",
    "familyPerson",
    "familyRemote"
  ];

  const groupSessions = ["groupPerson", "groupRemote"];

  const sessionInfoMap = {
    oneToOneRemote:
      "Our One to One Sessions are perfect for those that want to talk through their experiences in private, and allow for a more intimate discussion with one of our experienced buddies who will share their insights and experiences. ",
    oneToOnePerson:
      "Our One to One Sessions are perfect for those that want to talk through their experiences in private, and allow for a more intimate discussion with one of our experienced buddies who will share their insights and experiences. ",
    groupRemote:
      " Our Group Sessions are perfect for those who thrive on community and shared experiences. Here you will be part of a wide support network of fellow people going through a similar experience, all facilitated by one of our experienced buddies. ",
    groupPerson:
      " Our Group Sessions are perfect for those who thrive on community and shared experiences. Here you will be part of a wide support network of fellow people going through a similar experience, all facilitated by one of our experienced buddies. ",
    familyRemote:
      "These sessions allow families to discuss their current concerns, fears, and struggles and seek guidance in a private session with a buddy who will provide their insights, and a chance to discuss anything else in total privacy.",
    familyPerson:
      "These sessions allow families to discuss their current concerns, fears, and struggles and seek guidance in a private session with a buddy who will provide their insights, and a chance to discuss anything else in total privacy."
  };

  useEffect(() => emailjs.init("Scrmn43GP_EG4P1Ng"), []);

  const handleSessionSelect = (value) => {
    setSessionInfo(sessionInfoMap[value]);
    setSessionSelection(value);
  };
  const submitForm = (e) => {
    e.preventDefault();
    setSendText("Sending....");
    emailjs
      .sendForm("default_service", "template_kmi158f", form.current)
      .then((response) => {
        if (response.status === 200) {
          setFormSubmitted(true);
        } else {
          setSendText("Submit");
          setFormError(true);
        }
      })
      .catch((error) => {
        setSendText("Submit");
        setFormError(true);
      });
  };

  if (formSubmitted) {
    return (
      <div className="m-3 md:w-3/4 md:m-auto mt-4">
        <h1 className="text-center text-4xl mb-4">Form submitted</h1>
        <p className="text-xl mb-4">
          Thank you for your booking request, we'll be in touch soon to confirm
          your chosen timeslot and provide information for payments
        </p>
      </div>
    );
  }
  return (
    <div className="m-3 md:w-3/4 md:m-auto mt-4">
      <h1 className="text-center text-4xl mb-4">Make a Booking</h1>
      <div className="w-1/2 m-auto">
        <h2 className="text-xl mb-4">
          Interested in booking in a session with us? Simply fill out the form
          below and we will get back to you to find a time that works or Call us
          on: <span className="font-bold">07359196553</span>
        </h2>
        <p className="mb-4">
          All of our sessons are:
          <ul className="list-disc list-inside mb-4">
            <li>50 minutes long</li>
            <li>
              Can be done in person at a location that suits you or remotely
            </li>
            <li>Are available to everyone.</li>
          </ul>
        </p>
        <p className="mb-4">
          Note: The prices for each type of session are shown on the selection
          menu below, you will not need to pay until we respond to you to
          confirm your spot, at which time you will also be sent instructions on
          how to pay
        </p>
        <form ref={form} onSubmit={(e) => submitForm(e)}>
          <div className="mb-5">
            <label
              for="contact_name"
              className="mb-3 block text-base font-medium text-[#07074D]"
            >
              Your name:{" "}
            </label>
            <input
              type="text"
              name="contact_name"
              className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              value={nameValue}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="mb-5">
            <label
              for="contact_email"
              className="mb-3 block text-base font-medium text-[#07074D]"
            >
              Your email:{" "}
            </label>
            <input
              type="text"
              name="contact_email"
              value={emailValue}
              className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-5">
            <label
              for="contact_number"
              className="mb-3 block text-base font-medium text-[#07074D]"
            >
              Your contact number:{" "}
            </label>
            <input
              type="text"
              name="contact_number"
              value={contactNumberValue}
              className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              onChange={(e) => setContactNumber(e.target.value)}
            />
          </div>
          <div className="mb-5">
            <label
              for="session_selection"
              className="mb-3 block text-base font-medium text-[#07074D]"
            >
              Which session would you like to book?
            </label>
            <select
              name="session_selection"
              value={sessionSelection}
              className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              onChange={(e) => handleSessionSelect(e.target.value)}
            >
              <option value="" disabled selected>
                Select your session option
              </option>
              <option value="oneToOneRemote">1 - 2 - 1 (Remote) - £55</option>
              <option value="oneToOnePerson">
                1 - 2 - 1 (In Person) - £75
              </option>
              <option value="groupRemote">Group Session (Remote) - £25</option>
              <option value="groupPerson">
                Group Session (In Person) - £35
              </option>
              <option value="familyRemote">
                Family Session (Remote) - £70
              </option>
              <option value="familyPerson">Family (In Person) - £100</option>
            </select>
          </div>
          <p className="mb-4">{sessionInfo}</p>
          <div
            className={`mb-5 ${sessionsWithTimeslots.includes(sessionSelection) ? "block" : "hidden"}`}
          >
            <label
              for="timeslots_selected"
              className="mb-3 block text-base font-medium text-[#07074D]"
            >
              Please let us know the timeslots you would like to enquire about
              (multiple options give the best chance of us finding a slot to
              accomodate you). Please enter both the days of the week and the
              times you are available
            </label>
            <textarea
              name="timeslots_selected"
              value={timeslotValue}
              className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              onChange={(e) => setTimeslotValue(e.target.value)}
            />
          </div>
          <div
            className={`mb-5 ${groupSessions.includes(sessionSelection) ? "block" : "hidden"}`}
          >
            <label
              for="timeslots_selected"
              className="mb-3 block text-base font-medium text-[#07074D]"
            >
              Our group sessions are run every Tuesday and Thursday at 6:00pm -
              6:50pm & 7:00pm - 7:50pm. Please let us know which session you
              would like to attend
            </label>
            <textarea
              name="timeslots_selected"
              value={timeslotValue}
              className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              onChange={(e) => setTimeslotValue(e.target.value)}
            />
          </div>
          <div>
            <label
              for="additional_questions"
              className="mb-3 block text-base font-medium text-[#07074D]"
            >
              Is there anything else you'd like us to know / any other requests
              you'd like to make as part of your booking?
            </label>
            <textarea
              name="additional_questions"
              value={questionValue}
              className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              onChange={(e) => setQuestionValue(e.target.value)}
            />
          </div>
          {formError && (
            <div>
              <p className="text-red-500">
                Form submission failed, please make sure you've filled in all
                fields and try again
              </p>
            </div>
          )}
          <div className="text-center">
            <input
              type="submit"
              value={sendingText}
              disabled={sendingText === "Sending..." ? true : false}
              className="hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-base font-semibold text-white outline-none mb-4"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Book;
