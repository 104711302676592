import { Link } from "react-router-dom";

const Mission = () => {
  return (
    <div>
      <div className="bg-blue-800 text-white h-32 justify-center flex">
        <h1 className="text-3xl m-auto">
          Our mission, what we do and why we do it
        </h1>
      </div>
      <div className="m-3 md:w-3/4 flex md:m-auto flex-col">
        <h2 className="text-2xl text-blue-800 mt-4">
          Our mission drives all, and everything we do is to achieve it
        </h2>
        <p className="mt-4 text-xl">
          In our judicial system, there is currently a wealth of support for
          both the victims and those accused of comitting criminal offenses.
          However, there is a large gap in the form of support for those close
          to these people, be they Friends, Family or anyone else.
        </p>
        <p className="mt-4 text-xl">
          Here at Family & Friends Stronghold, we noticed this gap through
          experience, and decided that something needed to change. We felt that
          all voices needed to be heard, and that everyone affected by the
          criminal justice system was entitled to the support they need, to feel
          validated in their struggles, and simply to have someone with which to
          share their experiences
        </p>
        <p className="mt-4 text-xl">
          To this end, we formed as a CIC (Nonprofit) with the aim to help
          anyone currently going through this situation, to provide a buddy and
          support system and to give voices back to those struggling both during
          and after this process.
        </p>
        <p className="mt-4 text-xl">
          We are not a counselling service, and we are not here to give advice
          and force action or changes. We are simply here to listen, to discuss
          and to use our experience to help in whatever way we can. We belive
          that through this support structure, we can bring brighter futures to
          all those navigating these difficult situations.
        </p>
      </div>
      <div className="m-3 md:w-3/4 md:m-auto mt-4">
        <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-7">
          <div className="bg-purple-700 text-white flex flex-col justify-center">
            <div>
              <h2 className="text-center text-3xl mb-2 ml-2 mr-2">
                Our services
              </h2>
              <p className="text-center ml-2 mr-2">
                To help us achieve are mission, we provide a variety of
                services, as the below page explains
              </p>
              <div className="flex justify-center">
                <Link
                  to="/services"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 m-auto mt-7"
                >
                  Information on our services
                </Link>
              </div>
            </div>
          </div>
          <div className="bg-blue-900 text-white flex flex-col justify-center">
            <h2 className="text-center text-3xl mb-2 mt-6 ml-2 mr-2">
              Further support
            </h2>
            <p className="text-center ml-2 mr-2">
              We are happy to help endorse the following additional support
              opportunities that assist us in achieving our mission
            </p>
            <div className="flex justify-center">
              <a
                href="https://www.victimsupport.org.uk/help-and-support/how-we-can-help/"
                target="_blank"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800  m-auto mt-7 mb-6"
              >
                Additional Support
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;
