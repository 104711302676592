import { Link } from "react-router-dom";

const People = () => {
  return (
    <div>
      <div className="bg-blue-800 text-white h-32 justify-center flex">
        <h1 className="text-3xl m-auto">
          The people that make up the Stronghold
        </h1>
      </div>
      <div className="m-3 md:w-3/4 flex md:m-auto flex-col">
        <h2 className="text-2xl text-blue-800 mt-4">
          Our people allow us to achieve our mission
        </h2>
        <p className="mt-4 text-xl">
          We would be nothing without our people, their dedication and support
          are what allow us to achieve our mission and bring support to others.
          They all bring their own experience, knowledge and unique supporting
          style to help ensure everyone is given the support they deserve
        </p>
        <h3 className="mt-4 text-2xl text-blue-800">
          Our Founder - Alex Richards
        </h3>
        <img
          src="https://i.imgur.com/p3OyFEf.jpeg"
          width="300"
          height="20"
          alt=""
          className="m-auto"
        />
        <p className="mt-4 text-xl">
          Our founder, Alex Richards has gone through the experience that this
          non-profit is made to address when a close family member was arrested
          and charged with a serious criminal offence. The close family member
          was given a lot of help and links to services to aid in their
          recovery, however, they were given no assistance.
        </p>
        <p className="mt-4 text-xl">
          Checking what support was out there, it was found that many focused on
          families when the offender is released from prison or in prison. Our
          aim is to provide support through the entire process, from arrest to
          post-outcome and beyond.
        </p>
      </div>
      <div className="m-3 md:w-3/4 md:m-auto mt-4">
        <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-7">
          <div className="bg-purple-700 text-white flex flex-col justify-center">
            <div>
              <h2 className="text-center text-3xl mb-2 ml-2 mr-2">
                Our mission
              </h2>
              <p className="text-center ml-2 mr-2">
                More information about the mission our people help us to achieve
                can be found below
              </p>
              <div className="flex justify-center">
                <Link
                  to="/mission"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 m-auto mt-7"
                >
                  Mission Information
                </Link>
              </div>
            </div>
          </div>
          <div className="bg-blue-900 text-white flex flex-col justify-center">
            <h2 className="text-center text-3xl mb-2 mt-6 ml-2 mr-2">
              Interested in helping?
            </h2>
            <p className="text-center ml-2 mr-2">
              We are always interested to here from people who wish to help with
              our mission, be that directly or through different kind of
              support. Interested? Contact us on the below form to find our more
            </p>
            <div className="flex justify-center">
              <Link
                to="/contact"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800  m-auto mt-7 mb-6"
              >
                Send us a message
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default People;
