import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <div>
      {/* <div className="hidden xl:block bg-[url('https://i.imgur.com/BnuvBEO.png')] h-screen w-screen bg-cover" /> */}
      <div className="hidden lg:block">
        <img src="https://i.imgur.com/BnuvBEO.png" />
      </div>
      <div className="bg-blue-800 text-white">
        <div className="md:w-3/4 flex m-auto items-center justify-center flex-col">
          <h1 className="text-center text-3xl mb-4 mt-2">
            Welcome to Family & Friends Stronghold
          </h1>
          <p className="m-auto text-center mb-4">
            We provide emotional support and buddy systems to help aid the
            families and friends of those currently going through the Criminal
            justice system.
          </p>
          <p className="m-auto text-center mb-4">
            While currently there is plenty of support for victims and the
            criminally accused, those close to them can often be forgotten. We
            aim to provide a safe place and the support that is often so
            desperately needed for those placed in a situation outside of their
            control
          </p>
          <p className="m-auto text-center mb-4">
            It is important to note, we are not a counselling service and do not
            give out advice, we only talk about our experiences and what helped
            us through our situations, and provide a safe space to listen and
            allow you to be heard.
          </p>
        </div>
      </div>
      <div className="md:w-3/4 m-auto mt-4">
        <div className="p-4 grid md:grid-cols-3 grid-cols-1  gap-7">
          <div className="bg-purple-700 text-white flex flex-col justify-center">
            <div>
              <h2 className="text-center text-3xl mb-2 ml-2 mr-2">
                Our Services
              </h2>
              <p className="text-center ml-2 mr-2">
                We provide several services to aid in our mission, primairly
                focused around 1-2-1 discussions, and group forums allowing
                experiences to be shared.
              </p>
              <div className="flex justify-center">
                <Link
                  to="/services"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 m-auto mt-7"
                >
                  Info on our services
                </Link>
              </div>
            </div>
          </div>
          <div className="bg-blue-900 text-white flex flex-col justify-center">
            <h2 className="text-center text-3xl mb-2 mt-6 ml-2 mr-2">
              Our Mission
            </h2>
            <p className="text-center ml-2 mr-2">
              Our mission is to ensure no person feels left behind or forgotten
              about while someone close to them is navigating the criminal
              justice system. We want to encourage all to feel like their voice
              is heard, their experiences valid and provide help and support to
              the best of our abilities.
            </p>
            <div className="flex justify-center">
              <Link
                to="/mission"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800  m-auto mt-7 mb-6"
              >
                View more about our mission
              </Link>
            </div>
          </div>
          <div className="bg-purple-700 text-white flex flex-col justify-center">
            <h2 className="text-center text-3xl mb-2 ml-2 mr-2">Our People</h2>
            <p className="text-center ml-2 mr-2">
              Our people are the key to fulfilling our mission, and are willing
              to go the extra mile to help achieve that goal. You can find our
              more about the people that drive us forward here
            </p>
            <div className="flex justify-center">
              <Link
                to="/people"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 m-auto mt-7"
              >
                See our team
              </Link>
            </div>
          </div>
        </div>
        <div className="m-3 md:w-3/4 flex md:m-auto items-center justify-center flex-col">
          <h1 className="text-center text-3xl mb-4 mt-2 ">
            Interested in booking a session?
          </h1>
          <p>
            If you're interested in booking into a session with one of our
            supporters, or attending a group support session, visit our booking
            page to find what works for you
          </p>
          <div className="">
            <div className="p-4 grid grid-cols-1 md:grid-cols-1 gap-7">
              <div className="bg-purple-700 text-white flex flex-col justify-center">
                <div>
                  <h2 className="text-center text-3xl mb-2 ml-2 mr-2">
                    Book a session
                  </h2>
                  <img
                    src="https://images.pexels.com/photos/4099099/pexels-photo-4099099.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    width="300"
                    height="20"
                    alt=""
                    className="m-auto"
                  />
                  <p className="text-center ml-2 mr-2 mt-4">
                    We offer a variety of sessions, including 1 - 2 - 1
                    sessions, Group sessions and Family sessions, allowing you
                    to select the session and support that is right for you. For
                    more info and to book a session, visit our booking page
                    below.
                  </p>
                  <div className="flex justify-center">
                    <Link
                      to="/book"
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 m-auto mt-7 mb-6"
                    >
                      Book here
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-solid border-2 border-indigo-600 mb-6 md:w-3/4 m-auto">
          <h2 className="text-3xl text-center mb-3">
            Want to help support our cause?
          </h2>
          <p className="ml-3 mr-3">
            We are always looking for oppertunities to improve our services and
            find new ways to achieve our mission. Often, this involves resources
            beyond our means and so any additional help is always appreciated.
            We accept donations directly to our account, the details of which
            are:
          </p>
          <p className="text-center">
            <span className="font-bold">Name: </span>Family & Friends Stronghold
            CIC
          </p>
          <p className="text-center">
            <span className="font-bold">Bank:</span> The Co-operative Bank
          </p>
          <p className="text-center">
            <span className="font-bold">Account Number:</span> 67334382
          </p>
          <p className="text-center">
            <span className="font-bold">Sort Code:</span> 08-92-99
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
