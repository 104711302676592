const Privacy = () => {
  return (
    <div className="w-11/12 md:w-2/3 m-auto">
      <h1 className="text-4xl mb-4">Privacy Policy</h1>
      <h2 className="font-bold text-xl mb-4">Effective Date: 24/03/24</h2>
      <p className="mb-4">
        Family & Friends Stronghold CIC ("we", "us", or "our") is committed to
        protecting the privacy and security of your personal information. This
        Privacy Policy explains how we collect, use, disclose, and safeguard
        your information when you visit our website or otherwise interact with
        us.
      </p>
      <h2 className="text-xl font-bold mb-2">Information We Collect</h2>
      <p className="mb-2">
        We may collect personal information from you when you interact with us,
        including when you visit our website, make a donation, sign up for our
        newsletter, or participate in our programs or events. The types of
        personal information we may collect include:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          Contact information (such as name, email address, postal address, and
          phone number)
        </li>
        <li>Demographic information (such as age, gender, and interests)</li>
        <li>
          Financial information (such as credit card or bank account details for
          donations)
        </li>
        <li>Other information you provide to us voluntarily</li>
      </ul>
      <h2 className="text-xl font-bold mb-2">How We Use Your Information </h2>
      <p className="mb-2">
        We may use the information we collect from you for the following
        purposes:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>To communicate with you and provide the services you request</li>
        <li>To process donations and acknowledge your support</li>
        <li>
          To send you newsletters, updates, and other information about our
          organization and our programs{" "}
        </li>
        <li>To analyze and improve our website and services </li>
        <li>To comply with legal and regulatory requirements </li>
        <li>
          {" "}
          For any other purpose disclosed to you at the time we collect your
          information or with your consent{" "}
        </li>
      </ul>
      <h2 className="text-xl font-bold mb-2">
        Disclosure of Your Information{" "}
      </h2>
      <p className="mb-2">
        We may share your personal information with third parties in the
        following circumstances:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          With service providers who help us operate our website, process
          donations, or provide other services on our behalf
        </li>
        <li>
          With our partners, sponsors, or affiliates for purposes related to our
          mission and programs
        </li>
        <li>
          When required by law or in response to legal process With your consent
          or at your direction
        </li>
      </ul>
      <h2 className="text-xl font-bold mb-2">Data Security </h2>
      <p className="mb-2">
        We take reasonable measures to protect the security of your personal
        information and to prevent unauthorized access, disclosure, alteration,
        or destruction. However, please be aware that no method of transmission
        over the Internet or method of electronic storage is completely secure.
      </p>
      <h2 className="text-xl font-bold mb-2">Your Rights and Choices </h2>
      <p className="mb-2">
        You have certain rights and choices regarding your personal information,
        including the right to access, correct, or delete your information, as
        well as the right to object to or restrict certain processing
        activities. Please contact us using the information provided below if
        you would like to exercise any of these rights.
      </p>
      <h2 className="text-xl font-bold mb-2">Changes to Our Privacy Policy </h2>
      <p className="mb-2">
        We may update this Privacy Policy from time to time to reflect changes
        in our practices or for other operational, legal, or regulatory reasons.
        Any such changes will be posted on this page with an updated effective
        date.
      </p>
      <h2 className="text-xl font-bold mb-2">Contact Us </h2>
      <p className="mb-5">
        If you have any questions about our Privacy Policy, or if you would like
        to exercise your rights regarding your personal information, please
        contact us at enquiries@fandfs.co.uk.
      </p>
    </div>
  );
};

export default Privacy;
