const Community = () => {
  return (
    <div>
      <div className="bg-blue-800 text-white h-32 justify-center flex">
        <h1 className="text-3xl m-auto">
          Community Interests and how we give back
        </h1>
      </div>
      <div className="m-3 md:w-3/4 flex md:m-auto flex-col">
        <h2 className="text-2xl text-blue-800 mt-4">
          Community drives everything we do
        </h2>
        <p className="mt-4 text-xl">
          As a Community Interest Company (CIC), Family & Friends Stronghold are
          a committed non-profit, and we put all of our profits into both the
          company and improving the community around us.
        </p>
        <p className="mt-4 text-xl">
          The money generated by our session offerings, donations and income
          streams, go back into the business and can also be used to provide a
          funding pot for those who are of low income and wish to receive a
          support system or for those who may need subsidised costs to see more
          professional help.
        </p>
        <p className="mt-4 text-xl">
          We are also constantly looking for new ways to give back to the
          community, and particularly aid and support in our mission of
          providing support to those close to someone going through the Criminal
          justice system.
        </p>
      </div>
      <div className="m-3 md:w-3/4 md:m-auto mt-4">
        <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-7">
          <div className="bg-purple-700 text-white flex flex-col justify-center">
            <div>
              <h2 className="text-center text-3xl mb-2 ml-2 mr-2">
                CIC Information
              </h2>
              <p className="text-center ml-2 mr-2">
                For more information about Community interest companies, check
                the link below
              </p>
              <div className="flex justify-center">
                <a
                  target="_blank"
                  href="https://www.gov.uk/government/organisations/office-of-the-regulator-of-community-interest-companies"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 m-auto mt-7"
                >
                  Government information regarding CIC's
                </a>
              </div>
            </div>
          </div>
          <div className="bg-blue-900 text-white flex flex-col justify-center">
            <h2 className="text-center text-3xl mb-2 mt-6 ml-2 mr-2">
              Further support
            </h2>
            <p className="text-center ml-2 mr-2">
              We are happy to help endorse the following additional support
              oppertunities
            </p>
            <div className="flex justify-center">
              <a
                target="_blank"
                href="https://www.victimsupport.org.uk/help-and-support/how-we-can-help/"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800  m-auto mt-7 mb-6"
              >
                Additional Support
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Community;
