const Cookies = () => {
  return (
    <div className="w-11/12 md:w-2/3 m-auto">
      <h1 className="text-4xl mb-4">Cookie Policy</h1>
      <h2 className="font-bold text-xl mb-4">Effective Date: 24/03/24</h2>
      <p className="mb-4">
        This Cookie Policy explains how Family & Friends Stronghold CIC ("we",
        "us", or "our") uses cookies and similar technologies on our website. By
        using our website, you consent to the use of cookies in accordance with
        this Cookie Policy.
      </p>
      <h2 className="text-xl font-bold mb-2">What are Cookies?</h2>
      <p className="mb-2">
        Cookies are small text files that are placed on your computer or mobile
        device when you visit a website. They are widely used to make websites
        work more efficiently and to provide information to the owners of the
        site.
      </p>
      <h2 className="text-xl font-bold mb-2">How We Use Cookies</h2>
      <p className="mb-2">We use cookies for the following purposes:</p>
      <ul className="list-disc list-inside mb-4">
        <li>
          Essential Cookies: These cookies are necessary for the operation of
          our website. They enable you to navigate our site and use its
          features. Without these cookies, services you have asked for cannot be
          provided.
        </li>
        <li>
          Analytical/Performance Cookies: These cookies allow us to recognize
          and count the number of visitors to our website and to see how
          visitors move around when they are using it. This helps us improve the
          way our website works, for example, by ensuring that users find what
          they are looking for easily.
        </li>
        <li>
          Functionality Cookies: These cookies are used to recognize you when
          you return to our website. This enables us to personalize our content
          for you and remember your preferences (for example, your choice of
          language or region).
        </li>
        <li>
          Targeting Cookies: These cookies record your visit to our website, the
          pages you have visited, and the links you have followed. We will use
          this information to make our website and the advertising displayed on
          it more relevant to your interests. We may also share this information
          with third parties for this purpose.
        </li>
      </ul>
      <h2 className="text-xl font-bold mb-2">Your Choices Regarding Cookies</h2>
      <p className="mb-2">
        You can choose to accept or decline cookies. Most web browsers
        automatically accept cookies, but you can usually modify your browser
        settings to decline cookies if you prefer. However, please note that
        disabling cookies may prevent you from taking full advantage of the
        website.
      </p>
      <h2 className="text-xl font-bold mb-2">Changes to Our Cookie Policy</h2>
      <p className="mb-2">
        We may update this Cookie Policy from time to time to reflect changes in
        our practices or for other operational, legal, or regulatory reasons.
        Any such changes will be posted on this page with an updated effective
        date.
      </p>
      <h2 className="text-xl font-bold mb-2">Contact Us </h2>
      <p className="mb-5">
        If you have any questions about our Cookie Policy, please contact us at
        enquiries@fandfs.co.uk.
      </p>
    </div>
  );
};

export default Cookies;
