const TermsAndConditions = () => {
  return (
    <div className="w-11/12 md:w-2/3 m-auto">
      <h1 className="text-4xl mb-4">
        Family & Friends Stronghold Counseling Services
      </h1>
      <h2 className="font-bold text-xl mb-4">Terms and Conditions</h2>
      <p className="mb-4">
        Services Offered: Family & Friends Stronghold provides buddy support to
        individuals, families, and groups. These services may include but are
        not limited to individual support, family support, group support
        sessions, and workshops.
      </p>
      <h2 className="text-xl font-bold mb-2">Confidentiality</h2>
      <p className="mb-2">
        All information shared during buddy support sessions is strictly
        confidential, except in the following circumstances:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>If there is an immediate risk of harm to the client or others.</li>
        <li>
          If there is suspected abuse or neglect of a child, elder, or
          vulnerable adult.
        </li>
        <li>If required by law or court order.</li>
        <li>If there is a duty to warn or protect against imminent harm.</li>
        <li>
          If there is a need for consultation or supervision with another mental
          health professional to ensure the highest standard of care.
        </li>
        <p className="mb-2">
          Clients will be informed of any limitations to confidentiality before
          the buddy support process begins.
        </p>
      </ul>
      <h2 className="text-xl font-bold mb-2">Payment and Fees</h2>
      <p className="mb-2">
        Payment for buddy support services is due before the time of service
        unless other arrangements have been made in advance. Fees for services
        are defined on the companies website.
      </p>
      <h2 className="text-xl font-bold mb-2">Cancellation Policy</h2>
      <p className="mb-2">
        Clients are required to provide at least 24 hours notice for
        cancellations or rescheduling of appointments. Failure to do so will
        result in a cancellation fee for the cost of the session.
      </p>
      <h2 className="text-xl font-bold mb-2">Scope of Services</h2>
      <p className="mb-2">
        Buddy Support services provided by Family & Friends Stronghold CIC are
        intended to support clients going through the emotional journey before,
        during and after a loved one or cared for person going through the Crown
        Prosecution Service. . However, buddy support services are not a
        guarantee of specific outcomes, and results may vary depending on
        individual circumstances.
      </p>
      <h2 className="text-xl font-bold mb-2">Client Responsibilities</h2>
      <p className="mb-2">
        Clients are responsible for actively participating in the buddy support
        process, including attending scheduled sessions, and providing honest
        and open communication with the buddy.
      </p>
      <h2 className="text-xl font-bold mb-2">Professional Boundaries</h2>
      <p className="mb-2">
        Buddy’s at Family & Friends Stronghold CIC adhere to professional
        ethical standards and maintain appropriate boundaries with clients. Any
        concerns regarding boundaries or ethical conduct should be addressed
        promptly with the Buddy or the CIC’s director.
      </p>
      <h2 className="text-xl font-bold mb-2">Professional Boundaries</h2>
      <p className="mb-2">
        Buddy’s at Family & Friends Stronghold CIC adhere to professional
        ethical standards and maintain appropriate boundaries with clients. Any
        concerns regarding boundaries or ethical conduct should be addressed
        promptly with the Buddy or the CIC’s director.
      </p>
      <h2 className="text-xl font-bold mb-2">Discounts</h2>
      <p className="mb-2">
        If clients who are provided discounted rate services after having gone
        through the firm's eligibility criteria are believed or have been found
        to have lied to obtain discounted services, will be unable to use the
        service going forward.
      </p>
      <h2 className="text-xl font-bold mb-2">Feedback and Complaints</h2>
      <p className="mb-2">
        Family & Friends Stronghold CIC welcomes feedback from clients regarding
        their buddy support experience. Clients who have concerns or complaints
        are encouraged to bring them to the attention of the buddy or the CIC’s
        director for resolution.
      </p>
      <h2 className="text-xl font-bold mb-2">Modification of Terms</h2>
      <p className="mb-4">
        These terms and conditions may be modified or updated by Family &
        Friends Stronghold CIC as needed. Clients will be notified of any
        changes to these terms and conditions.
      </p>
      <p className="mb-5">
        By engaging in buddy support services with Family & Friends Stronghold
        CIC, clients agree to abide by these terms and conditions. If you have
        any questions or concerns about these terms, please contact us for
        clarification.
      </p>
    </div>
  );
};

export default TermsAndConditions;
